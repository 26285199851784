export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'APP_DASHBOARD',
    action: 'read',
  },
  {
    title: 'Servis Kayıtları',
    icon: 'SettingsIcon',
    route: 'Services',
    resource: 'CUSTOMER_SERVICE_LIST',
    action: 'read',
  },
  {
    title: 'Makina Kartları',
    icon: 'CodesandboxIcon',
    route: 'Machines',
    resource: 'CUSTOMER_MACHINES_LIST',
    action: 'read',
  },
]
